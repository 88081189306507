import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import useGlobals from '@hooks/useGlobals';
import ProgressBar from '@components/ProgressBar';
import ZoomBlock from '@components/ZoomBlock';
import PinBlock from '@components/PinBlock';
import Header from '@components/Header';
import ArticleFooter from '@components/ArticleFooter';

import { THomePage } from '@local-types/data';

import styles from './HomeLayout.module.scss';

const AuthorQuote = dynamic(() => import('@components/AuthorQuote'), {
  ssr: false,
});

type THomeLayout = {
  withDisclimer?: boolean;
  articles?: any;
  currentUrl: string;
  children?: any;
  homeData?: any;
};

const HomeLayout: FC<THomeLayout> = ({
  withDisclimer,
  homeData,
  children,
  articles,
  currentUrl,
}) => {
  const [{}, { isFullScreen, isDarkTheme }] = useGlobals();

  const headline = homeData?.headline as THomePage['headline'];
  const xUrl = homeData?.x_url;
  const linkedinUrl = homeData?.linkedIn_url;
  const facebookUrl = homeData.facebook_url;
  const email = homeData?.email;
  const profileImgMobile = homeData.profile_image_mobile.data?.attributes.url;
  const profileImgDesktop = homeData.profile_image_desktop.data?.attributes.url;

  return (
    <div
      className={cn({
        [styles.darkBg]: isDarkTheme && currentUrl === '/',
        [styles.pageWrapper]: currentUrl === '/',
      })}
    >
      <ProgressBar />
      <Header
        withDisclimer={!!withDisclimer}
        articles={articles}
        activeUrl={currentUrl}
      />
      {currentUrl === '/' && (
        <AuthorQuote
          data={headline}
          profileImgMobile={profileImgMobile}
          profileImgDesktop={profileImgDesktop}
          x={xUrl}
          facebook={facebookUrl}
          email={email}
          linkedin={linkedinUrl}
        />
      )}
      <main className={styles.main}>
        <article
          className={cn(styles.article, {
            [styles.fullscreen]: isFullScreen,
            [styles.darkTheme]: isDarkTheme,
            [styles.homePage]: currentUrl === '/',
          })}
        >
          <section className={styles.section}>{children}</section>
        </article>
      </main>
      <ArticleFooter />
      <PinBlock />
      <ZoomBlock />
    </div>
  );
};

export default HomeLayout;
